/*!
Homepage for Oakhurst home.
* Copyright 2023 OakhurstHome LLC (https://oakhursthome.com )
*/
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import HomeNavBar from "components/Navbars/HomeNavbar.js";
import HomePageHeader from "components/Headers/HomePageHeader.js";
import HomeFooter from "components/Footers/HomeFooter.js";

function HomePage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <HomeNavBar />
      <HomePageHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Modern cabin in private 5+ acres at Oakhurst near Yosemite</h2>
                <h5 className="description">
                  This ranch-style house is the perfect nature retreat. Located near Bass Lake and Yosemite South Gate.
                  Surrounded by 5 acres of wildlife, trees, and plenty to explore on this private property.
                  Experience real quiet and peace with the beautiful Mountain views from every part of this house.
                  The home itself is fully stocked with premium products and amenities for any of your needs.
                  Above all, KIDS Friendly and we try our best to make our guests happy.
                </h5>
                <br />
                <Button
                  className="btn-round"
                  color="info"
                  href="https://airbnb.com/h/oakhursthome"
                  target="_blank"
                >
                  See Details
                </Button>
              </Col>
            </Row>
            <br />
            <br />

          </Container>
        </div>
      </div>
      <HomeFooter />
    </>
  );
}

export default HomePage;
