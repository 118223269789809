import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
//
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//  <React.StrictMode>
//    <App />
//  </React.StrictMode>
// );

import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import HomePage from "views/examples/HomePage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import RegisterPage from "views/examples/RegisterPage.js";
// others

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/index" element={<Index />} />
            <Route path="/nucleo-icons" element={<NucleoIcons />} />
            <Route path="/landing-page" element={<HomePage />} />
            <Route path="/profile-page" element={<ProfilePage />} />
            <Route path="/register-page" element={<RegisterPage />} />
            <Route path="*" element={<Navigate to="/index" replace />} />
        </Routes>
    </BrowserRouter>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
